import { useUserStore } from '~/stores/user';
import { useCommonStore } from '~/stores/common';

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const commonStore = useCommonStore();

  if (process.client) {
    const user = await userStore.check().catch(() => null);

    if (!userStore.isLoggedIn || !user) {
      const query = to.query;

      if (userStore.isLoggedIn) {
        // await store.dispatch('user/logout');
      } else if (to.fullPath !== '/') {
        await commonStore.setRedirectUrl(to.fullPath);
        query.redirect = to.fullPath;
      }

      return navigateTo({ name: 'login', query });
    }

    const redirectUrl = await commonStore.getRedirectUrl();

    if (redirectUrl) {
      commonStore.removeRedirectUrl();
    }

    if (
      redirectUrl &&
      redirectUrl !== to.fullPath &&
      redirectUrl !== '/logout'
    ) {
      return navigateTo({
        path: redirectUrl,
      });
    }
  }
});
